// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCtu7668iGgpTKjOGEJ8-j0nAXytd63960",
  authDomain: "prformance-b3416.firebaseapp.com",
  projectId: "prformance-b3416",
  storageBucket: "prformance-b3416.appspot.com",
  messagingSenderId: "956536000813",
  appId: "1:956536000813:web:fff41767cb181fe73483a4",
  measurementId: "G-V5LHK025J4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { app, analytics };